import TransparentBackground from '../TransparentBackground'
import SideMenuContent from './content/SideMenuContent'

export default function SideMenuContainer({ showSideMenu, setShowSideMenu, children }) {
  const hideSideMenu = () => {
    setShowSideMenu(!showSideMenu)
  }

  const Sidebar = (
    <div
      className={`fixed top-0 left-0 z-10 h-full w-80 bg-gray-200 py-10 px-6 text-white duration-200 ease-in-out dark:bg-gray-800 ${
        showSideMenu ? 'translate-x-0 ' : '-translate-x-full'
      }`}
    >
      <SideMenuContent setShowSideMenu={setShowSideMenu} />
    </div>
  )

  return (
    <>
      <main className="h-full">{children}</main>
      <TransparentBackground onClick={hideSideMenu} show={showSideMenu} />
      <div className="flex flex-col items-center justify-center py-2">{Sidebar}</div>
    </>
  )
}
