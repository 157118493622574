import { useRouter } from 'next/router'

export default function SideMenuItem({ text, page, setShowSideMenu }) {
  const router = useRouter()
  return (
    <button
      onClick={() => {
        router.push(page)
        setShowSideMenu(false)
      }}
      className={`prose h-10 rounded-lg pl-3 text-left duration-100 ease-in-out hover:bg-gray-300 hover:text-gray-800 dark:prose-dark dark:hover:bg-gray-700 dark:hover:text-white`}
    >
      <span className="text-md">{text}</span>
    </button>
  )
}
