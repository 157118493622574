import dynamic from 'next/dynamic'

const DynamicHowToPlayModal = dynamic(() => import('@/components/modals/HowToPlayModal'))
const DynamicSupportModal = dynamic(() => import('@/components/modals/SupportModal'))
const DynamicStatsModal = dynamic(() => import('@/components/modals/StatsModal'))

const AllModals = ({ headerStateManager, totalStats }) => {
  return (
    <>
      <DynamicHowToPlayModal
        setHowToPlayModal={headerStateManager.setShowHowToPlayModal}
        showHowToPlayModal={headerStateManager.showHowToPlayModal}
      />

      <DynamicStatsModal
        setRankingModal={headerStateManager.setShowStatsModal}
        showRankingModal={headerStateManager.showStatsModal}
        totalStats={totalStats}
      />
    </>
  )
}

export default AllModals
