import '@/css/tailwind.css'
import '@/css/styles.css'
import '@/css/playcontroller.css'
import '@fontsource/inter/variable-full.css'

import { ThemeProvider } from 'next-themes'
import Head from 'next/head'

import siteMetadata from '@/data/siteMetadata'
import Analytics from '@/components/analytics'
import { ClientReload } from '@/components/ClientReload'
import SideMenuContainer from '@/components/sidemenu/SideMenuContainer'
import { useState } from 'react'
import Header from '@/components/header/Header'
import AllModals from '@/components/modals/AllModals'
import Div100vh from 'react-div-100vh'
import { useRouter } from 'next/router'

const isDevelopment = process.env.NODE_ENV === 'development'
const isSocket = process.env.SOCKET

export default function App({ Component, pageProps }) {
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [showHowToPlayModal, setShowHowToPlayModal] = useState(false)
  const [showStatsModal, setShowStatsModal] = useState(false)

  const headerStateManager = {
    setShowSideMenu,
    setShowHowToPlayModal,
    setShowStatsModal,
    showSideMenu,
    showStatsModal,
    showHowToPlayModal,
  }

  const [totalStats] = useState({
    played: 0,
    avgRoundWin: 0,
    won: 0,
    rounds: [0, 0, 0, 0, 0, 0],
  })

  const router = useRouter()

  // TODO: update this later on
  const isGamePage = () => {
    return router.pathname === '/'
  }

  return (
    <ThemeProvider attribute="class" defaultTheme={siteMetadata.theme}>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
      </Head>
      {isDevelopment && isSocket && <ClientReload />}
      <Analytics />
      <Div100vh className={`flex flex-col ${isGamePage() ? 'overflow-hidden' : ''}`}>
        <div className="grow-0">
          <Header headerStateManager={headerStateManager} />
        </div>
        <div className="grow">
          <SideMenuContainer
            showSideMenu={headerStateManager.showSideMenu}
            setShowSideMenu={headerStateManager.setShowSideMenu}
          >
            <Component {...pageProps} totalStats={totalStats} header />
          </SideMenuContainer>
        </div>
      </Div100vh>
      <div id="modal-root"></div>
      <AllModals
        headerStateManager={headerStateManager}
        totalStats={totalStats} // fix this (pass and update the state correctly)
      />
    </ThemeProvider>
  )
}
