const TransparentBackground = ({ onClick, show }) => {
  return (
    <>
      <div
        onClick={onClick}
        className={`fixed right-0 bottom-0 z-0 h-screen w-screen opacity-50 duration-300 ease-in-out ${
          show ? 'bg-black' : 'pointer-events-none'
        }`}
      ></div>
    </>
  )
}

export default TransparentBackground
