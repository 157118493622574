import siteMetadata from '@/data/siteMetadata'
import { useRouter } from 'next/router'

export default function SideMenuFooter({ setShowSideMenu }) {
  const router = useRouter()

  const copyrightText = `© 2022 - ${new Date().getFullYear()} ${
    siteMetadata.author
  } - All Rights Reserved.`
  return (
    <>
      <div className="prose w-full flex-col space-y-2 dark:prose-dark">
        <p className="text-center text-xs">{copyrightText}</p>
        <div className="flex flex-row justify-center">
          <button
            onClick={() => {
              router.push('imprint')
              setShowSideMenu(false)
            }}
            className="grow text-center text-xs hover:text-gray-700 hover:underline dark:text-gray-400 dark:hover:text-white"
          >
            Imprint
          </button>
          <button
            onClick={() => {
              router.push('privacypolicy')
              setShowSideMenu(false)
            }}
            className="grow text-center text-xs hover:text-gray-700 hover:underline dark:text-gray-400 dark:hover:text-white"
          >
            Privacy Policy
          </button>
        </div>
      </div>
    </>
  )
}
