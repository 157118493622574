import { useRouter } from 'next/router'
import SideMenuButton from '../buttons/SideMenuButton'
import siteMetadata from '@/data/siteMetadata'

export default function HeaderLeftSection({ headerStateManager }) {
  const router = useRouter()
  return (
    <div className="flex flex-row items-center space-x-4 leading-3">
      <SideMenuButton
        onClick={() => {
          headerStateManager.setShowSideMenu(true)
        }}
      />
      <button
        onClick={() => {
          router.push('/')
        }}
      >
        <h1 className="text-2xl font-semibold">{siteMetadata.headerTitle}</h1>
      </button>
    </div>
  )
}
