import { useRouter } from 'next/router'
import SideMenuFooter from './SideMenuFooter'
import HeaderTitle from '../../HeaderTitle'
import SideMenuItem from './SideMenuItem'

export default function SideMenuContent({ setShowSideMenu }) {
  const router = useRouter()
  return (
    <>
      <div className="flex h-full w-full flex-col justify-between">
        <div className="flex flex-col space-y-20">
          <button
            onClick={() => {
              router.push('/')
              setShowSideMenu(false)
            }}
            className="p-2 pl-3 text-left"
          >
            <HeaderTitle />
          </button>
          <div className="flex flex-col space-y-4">
            <SideMenuItem text={'All Games'} page={'games'} setShowSideMenu={setShowSideMenu} />
            <SideMenuItem text={'About'} page={'about'} setShowSideMenu={setShowSideMenu} />
            <SideMenuItem text={'FAQ'} page={'faq'} setShowSideMenu={setShowSideMenu} />
          </div>
        </div>
        <SideMenuFooter setShowSideMenu={setShowSideMenu} />
      </div>
    </>
  )
}
